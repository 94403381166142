import React from "react";
import SEO from "../components/atoms/seo";

const ThankYouNEWPage = () => (
  <div>
    <SEO title="Thank You" />
    {/* build new thank you page */}
  </div>
);

export default ThankYouNEWPage;
